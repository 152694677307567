import React from 'react'
import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'
import Divider from '../../components/Divider'

import CTABox from '../../components/CTABox'
import FloatIconFeature from '../../components/FloatIconFeature'
import CTALink from '../../components/CTALink'
import routes from '../../routes'
import ContactUs from '../../components/ContactUs'

const HeroImage =
  '../../assets/Business/Sanction List/Images/sanction_list_header.png'
const ComplianceImg =
  '../../assets/Business/Sanction List/Images/compliance.png'
// const DashboardServiceImg = '../../assets/Business/Sanction List/Images/service_dashboard1.png'
// const DashboardServiceImg2 = '../../assets/Business/Sanction List/Images/service_dashboard2.png'
const DashboardSignUpImg =
  '../../assets/Industries/Images/industries_header2.png'
const SavingsImage = '../../assets/Individuals/Images/savingsImage.png'

const useStyles = makeStyles((theme) => ({
  /**
   * || Reusable Components
   * ======================================= */
  sectionTitle: {
    marginBottom: 15,
    maxWidth: 280,

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 350,
      textAlign: 'left',
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 675,
      textAlign: 'left',
      fontSize: 20,
    },
  },
  sectionDescription: {
    maxWidth: 350,

    color: 'rgba(115, 115, 115, 1)',

    textAlign: 'center',
    whiteSpace: 'pre-line',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 500,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 600,
      textAlign: 'left',
      fontSize: 16,
    },
  },
  hero: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    // height: 650,
    paddingTop: 96,
    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,

    maxWidth: theme.custom.maxWidth,
    margin: 'auto',

    [theme.breakpoints.up('tablet')]: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',

      paddingTop: 145,
    },

    [theme.breakpoints.up('desktop')]: {
      paddingTop: 135,
      top: 40,
      height: 720,
    },
  },
  heroImage: {
    display: 'none !important',
    filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))',

    [theme.breakpoints.up('tablet')]: {
      display: 'block !important',
      position: 'relative',

      minWidth: 180,
      width: 425,
      height: 'auto',
      top: 35,
      marginRight: 50,
      marginLeft: 50,
    },
    [theme.breakpoints.up('desktop')]: {
      display: 'block !important',
      position: 'relative',
      height: 'auto',
      top: 0,
      marginRight: 50,
      marginLeft: 50,
    },

    [theme.breakpoints.up('1200')]: {
      width: 525,
    },

    [theme.breakpoints.up('1600')]: {
      width: 550,
    },

    [theme.breakpoints.up('desktopXL')]: {
      height: 'auto',
      marginLeft: 80,
    },
  },
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.up('tablet')]: {
      // padding: 28,
      width: '60%',
    },

    [theme.breakpoints.up('desktop')]: {
      width: '50%',
    },
  },
  heroTitle: {
    textAlign: 'left',
    fontSize: '32px',
    color: 'black',

    [theme.breakpoints.up('tablet')]: {
      fontSize: '28px',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: '35px',
    },

    [theme.breakpoints.up('1100')]: {
      fontSize: '44px',
    },

    [theme.breakpoints.up('1280')]: {
      fontSize: '56px',
    },
  },
  heroDescription: {
    maxWidth: 350,
    fontSize: 16,
    margin: '20px 0px',
    lineHeight: '24px',
    color: 'rgba(115, 115, 115, 1)',
    textAlign: 'left',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 450,
    },

    [theme.breakpoints.up('desktop')]: {
      // maxWidth: 'unset',
      lineHeight: '160%',
      maxWidth: 560,
    },
    [theme.breakpoints.up('1100')]: {
      fontSize: '18px',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      width: 'auto',
    },
  },

  signUpButton: {
    width: '90%',
    marginBottom: 10,
    fontSize: '16px',

    [theme.breakpoints.up('tablet')]: {
      marginRight: 10,
      fontSize: '14px',

      padding: 0,
      marginBottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '130px',
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: '16px',
      width: '170px',
    },
  },

  /**
   * || Compliance Section
   * ======================================= */
  complianceSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column-reverse',

    marginLeft: theme.gutters.mobile,
    marginRight: theme.gutters.mobile,
    marginTop: 60,
    marginBottom: 100,

    '& h2': {
      marginBottom: 0,
      [theme.breakpoints.up('desktop')]: {
        textAlign: 'left',
      },
    },

    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'center',
      flexDirection: 'row',

      marginTop: 130,
      marginBottom: 130,
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: 40,
      marginBottom: 130,
    },
    [theme.breakpoints.up('desktopXL')]: {
      marginTop: 180,
      marginBottom: 190,
    },
  },
  complianceImage: {
    width: '90%',
    marginTop: 35,
    maxWidth: 450,

    [theme.breakpoints.up('desktopXL')]: {
      maxWidth: 580,
    },
  },
  complianceColumnRight: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    maxWidth: 450,

    [theme.breakpoints.up('tablet')]: {
      alignItems: 'flex-start',
    },

    [theme.breakpoints.up('desktop')]: {
      marginLeft: 80,
    },

    [theme.breakpoints.up('desktopXL')]: {
      marginLeft: 130,
    },
  },

  /**
   * || Savings Section
   * ======================================= */
  savingsSection: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',
    flexDirection: 'column',

    marginTop: 80,
    // marginBottom: 'calc(60% - 120px)',
    padding: `100px ${theme.gutters.mobile}px 90px ${theme.gutters.mobile}px`,
    maxWidth: theme.custom.maxWidth,
    margin: 'auto',

    [theme.breakpoints.up('tablet')]: {
      background: 'none',
      flexDirection: 'row',
      padding: 0,
    },

    [theme.breakpoints.up('desktop')]: {
      // paddingBottom: 350,
      // marginBottom: 320,
      marginBottom: 50,
    },

    [theme.breakpoints.up('desktopXL')]: {
      // marginBottom: 350,
    },
  },
  savingsImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: 402,
  },
  savingsContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '50%',
    height: 402,
    padding: `100px ${theme.gutters.mobile}px 90px ${theme.gutters.mobile}px`,
    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',

    [theme.breakpoints.up('tablet')]: {
      padding: 30,
      paddingLeft: 'calc(40px + 1vw)',
    },

    [theme.breakpoints.up('750')]: {
      paddingLeft: 'calc(60px + 1vw)',
    },

    [theme.breakpoints.up('850')]: {
      paddingLeft: 'calc(70px + 1vw)',
    },

    [theme.breakpoints.up('desktop')]: {
      paddingTop: '5%',
      paddingLeft: '5%',
      height: 532,
    },

    [theme.breakpoints.up('1300')]: {
      paddingLeft: '7%',
    },

    [theme.breakpoints.up('1550')]: {
      paddingLeft: '9%',
    },

    // [theme.breakpoints.up('desktopXl')]: {
    //   paddingLeft: 200,
    // },
  },
  savingsButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 50,

    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'flex-start',
      marginTop: 20,
    },

    [theme.breakpoints.up('desktop')]: {
      height: 532,
      marginTop: 0,
    },
  },
  savingsTitle: {
    marginBottom: 15,
    maxWidth: 280,
    color: 'white',

    textAlign: 'center',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 320,
      fontSize: 18,
    },

    [theme.breakpoints.up('850')]: {
      fontSize: 18,
    },

    [theme.breakpoints.up('desktop')]: {
      maxWidth: 325,
      fontSize: 25,
    },
  },
  savingsDescription: {
    maxWidth: 280,
    fontSize: 12,
    color: 'white',

    textAlign: 'center',
    whiteSpace: 'pre-line',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: 377,
      textAlign: 'left',
      fontSize: 12,
    },

    [theme.breakpoints.up('desktop')]: {
      fontSize: 16,
      maxWidth: 519,
    },
  },
  savingsImageMobile: {
    width: '200px',
    marginTop: 25,
    marginBottom: 25,

    [theme.breakpoints.up('tablet')]: {
      marginBottom: 0,
      marginTop: 0,
      width: 230,
    },

    [theme.breakpoints.up('750')]: {
      width: 260,
    },

    [theme.breakpoints.up('850')]: {
      width: 290,
    },

    [theme.breakpoints.up('desktop')]: {
      width: 370,
    },
  },
  // savingsImage: {
  //   position: 'absolute !important',

  //   top: '80%',
  //   maxWidth: 650,
  //   width: '90%',

  //   background: '#F4F4F4',
  //   boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.35)',
  //   borderRadius: 10,

  //   [theme.breakpoints.up('desktop')]: {
  //     maxWidth: 850,
  //     top: '60%',

  //     borderRadius: 20,
  //   },
  //   savingsImage2: {
  //     position: 'absolute !important',

  //     top: '80%',
  //     maxWidth: 650,
  //     width: '90%',
  //     left: '100px',

  //     background: '#F4F4F4',
  //     boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.35)',
  //     borderRadius: 10,

  //     [theme.breakpoints.up('desktop')]: {
  //       maxWidth: 850,
  //       top: '60%',
  //       left: '100px',

  //       borderRadius: 20,
  //     },
  //   },
  // },

  /**
   * || Ecosystem Section
   * ======================================= */
  ecosystemSectionWrapper: {
    margin: 'auto',
    width: '100%',
    overflow: 'hidden',
    maxWidth: 1600,
  },
  ecosystemSection: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    padding: '100px 0px',
    // marginTop: 60,
    marginBottom: 50,

    overflow: 'hidden',

    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row-reverse',
      alignItems: 'flex-end',
    },

    [theme.breakpoints.up('desktopXL')]: {
      justifyContent: 'center',
    },

    '& h2': {
      [theme.breakpoints.up('desktop')]: {
        textAlign: 'left',
      },
    },

    '& p': {
      [theme.breakpoints.up('desktop')]: {
        textAlign: 'left',
      },
    },
  },
  ecosystemImage: {
    width: '90%',
    maxWidth: 650,
    marginTop: 55,
    filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))',

    [theme.breakpoints.up('desktop')]: {
      // position: 'absolute',
      // maxWidth: 'unset',
      // right: '40%',
      minWidth: 860,
      // maxWidth: 850,
      marginTop: 40,
      margin: 0,
      // margin: 'auto 0px',
    },
  },
  // ecosystemColumnLeft: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   flexDirection: 'column',

  //   margin: '0px 15px',

  //   [theme.breakpoints.up('desktop')]: {
  //     alignItems: 'flex-start',

  //     minWidth: 400,
  //     maxWidth: 670,
  //     margin: '0px 45px',
  //   },
  // },
  ecosystemColumnRight: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    margin: '0px 15px',

    [theme.breakpoints.up('tablet')]: {
      alignItems: 'center',

      minWidth: 400,
      maxWidth: 670,
      margin: '0px 45px',
    },

    [theme.breakpoints.up('desktop')]: {
      alignItems: 'flex-start',
      padding: 20,
      margin: 0,
      maxHeight: 560,
      marginBottom: 30,
    },

    [theme.breakpoints.up('1100')]: {
      marginRight: 70,
    },

    [theme.breakpoints.up('1200')]: {
      marginRight: 130,
    },
    [theme.breakpoints.up('1200')]: {
      marginRight: 170,
    },
    [theme.breakpoints.up('1300')]: {
      marginRight: 20,
      minWidth: 650,
      paddingLeft: 50,
      marginBottom: 10,
    },

    [theme.breakpoints.up('1400')]: {
      marginRight: 120,
    },

    [theme.breakpoints.up('1450')]: {
      marginRight: 390,
    },
    [theme.breakpoints.up('1520')]: {
      marginRight: 430,
    },
  },
  ecosystemFeature: {
    marginTop: 30,

    display: 'flex',
    width: '100%',

    '& svg': {
      marginRight: 10,
      width: 45,
      height: 45,
    },

    '& div': {
      width: '100%',

      '& p': {
        marginTop: 15,
      },
    },
  },
  ctaBox: {
    marginTop: -100,
    marginBottom: 50,
    [theme.breakpoints.up('tablet')]: {
      marginTop: -50,
      marginBottom: 120,
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: 0,
      marginBottom: 190,
    },
  },
}))

export default function SanctionList() {
  const classes = useStyles()
  const theme = useTheme()
  const isTabletUp = useMediaQuery(theme.breakpoints.up('tablet'))

  return (
    <main>
      <section className={classes.hero}>
        <div className={classes.heroContent}>
          {isTabletUp ? (
            <Typography variant='h1' className={classes.heroTitle}>
              Streamlined <br />
              Screening to Keep <br />
              Bad Actors{' '}
              <span style={{ color: 'rgba(47, 164, 59, 1)' }}>Sidelined</span>
            </Typography>
          ) : (
            <Typography variant='h1' className={classes.heroTitle}>
              Streamlined <br />
              Screening to Keep <br />
              Bad Actors <br />
              <span style={{ color: 'rgba(47, 164, 59, 1)' }}>Sidelined</span>
            </Typography>
          )}

          <Typography variant='body1' className={classes.heroDescription}>
            Keeping suspected criminals, fraudsters, and money launderers out is
            a key purpose of KYC/AML regulations, but staying compliant takes
            time and resources away from growing your business.
            <br />
            <br />
            {' \n\n'}
            <b style={{ color: 'rgba(47, 164, 59, 1)' }}>
              It doesn&apos;t have to.
            </b>{' '}
            CycurID&apos;s AI-Driven and fully automated screening tools filter
            out bad actors for you, enabling you to focus on the things that
            really matter to your bottom line.
          </Typography>
          <div className={classes.buttonContainer}>
            <CTALink
              className={classes.signUpButton}
              href={process.env.GATSBY_DASHBOARD_SIGNUP_LINK}
              target='_blank'
              rel='noopener'
            >
              Get Started
            </CTALink>
            <CTALink
              className={classes.signUpButton}
              href={routes.CONTACT}
              variant='outline'
            >
              Talk To Sales
            </CTALink>
          </div>
        </div>
        <StaticImage
          className={classes.heroImage}
          src={HeroImage}
          alt='Imme app features'
          placeholder='tracedSVG'
          loading='eager'
          quality={100}
        />
      </section>

      <section className={classes.complianceSection}>
        <StaticImage
          className={classes.complianceImage}
          src={ComplianceImg}
          alt='ID card verification'
          placeholder='blurred'
          quality={100}
        />
        <div className={classes.complianceColumnRight}>
          {isTabletUp && <Divider style={{ marginBottom: 12 }} />}
          <Typography variant='h2' className={classes.sectionTitle}>
            Don&apos;t Let Compliance Slow You Down
          </Typography>
          <FloatIconFeature
            title='Real-Time Screening'
            description='CycurID performs PEP and sanction list checks immediately in
                real time, scanning numerous government databases without any
                delay or intervention.'
          />
          <FloatIconFeature
            title='Real-Time Approvals'
            description="Get the answers you need right away. CycurID's AI-powered
            screening software returns results immediately with minimal
            processing time."
          />
          <FloatIconFeature
            title='Real-Time Updates'
            description="Don't worry about spending time updating your KYC data and 
            re-screening customers. CycurID automatically ensures out-of-date 
            customer data doesn’t become an issue."
          />
        </div>
      </section>

      {isTabletUp ? (
        <section className={classes.savingsSection}>
          <div className={classes.savingsContentContainer}>
            <Divider style={{ marginBottom: 12 }} />
            <Typography variant='h2' className={classes.savingsTitle}>
              Save Time, Save Money
            </Typography>
            <Typography variant='body1' className={classes.savingsDescription}>
              The cost of screening is far greater than just how much money it
              takes to run a background query. Adequate KYC and AML systems
              require your business to allocate both people and capital just to
              stay compliant with legal requirements.
            </Typography>
            <br />
            <Typography variant='body1' className={classes.savingsDescription}>
              We solve this problem for you. With fully-automated AI software
              providing Continuous KYC, and built-in auditing tools to make
              reporting easy, CycurID enables you to allocate those valuable
              resources where you really need them.
            </Typography>
            <div className={classes.savingsButtonContainer}>
              <CTALink
                className={classes.signUpButton}
                href='https://imme.io/'
                variant='fill'
              >
                Visit Imme
              </CTALink>
            </div>
          </div>
          <div className={classes.savingsImageContainer}>
            <StaticImage
              className={classes.savingsImageMobile}
              src={SavingsImage}
              alt='Mulitple Documents'
              placeholder='blurred'
              quality={100}
            />
          </div>
        </section>
      ) : (
        // mobile version
        <section className={classes.savingsSection}>
          <Divider style={{ marginBottom: 12 }} />
          <Typography variant='h2' className={classes.savingsTitle}>
            Save Time, Save Money
          </Typography>
          <Typography variant='body1' className={classes.savingsDescription}>
            The cost of screening is far greater than just how much money it
            takes to run a background query. Adequate KYC and AML systems
            require your business to allocate both people and capital just to
            stay compliant with legal requirements.
          </Typography>
          <StaticImage
            className={classes.savingsImageMobile}
            src={SavingsImage}
            alt='Mulitple Documents'
            placeholder='blurred'
            quality={100}
          />
          <Typography variant='body1' className={classes.savingsDescription}>
            We solve this problem for you. With fully-automated AI software
            providing Continuous KYC, and built-in auditing tools to make
            reporting easy, CycurID enables you to allocate those valuable
            resources where you really need them.
          </Typography>
          <div className={classes.savingsButtonContainer}>
            <CTALink
              className={classes.signUpButton}
              href='https://imme.io/'
              variant='fill'
            >
              Visit Imme
            </CTALink>
          </div>
        </section>
      )}

      <section className={classes.ecosystemSectionWrapper}>
        <div className={classes.ecosystemSection}>
          <div className={classes.ecosystemColumnRight}>
            <Divider style={{ marginBottom: 20 }} />
            <Typography variant='h2' className={classes.sectionTitle}>
              A Fully Self-Contained Ecosystem
            </Typography>
            <Typography variant='body1' className={classes.sectionDescription}>
              At every step of the process, all of the screening we perform is
              done entirely within CycurID&apos;s ecosystem. At no point do we
              outsource anything, preventing harmful data leaks from affecting
              your business.
            </Typography>
            <FloatIconFeature
              title='Consolidated Compliance'
              description={`CycurID's screening procedures are entirely performed by our
            automated AI. We do not send personal data to human "ID Experts"
            at third-party firms or in foreign countries.`}
            />
            <FloatIconFeature
              title='Secure By Design'
              description={
                <>
                  No customer information is stored inside{' '}
                  <b style={{ color: 'black' }}>or</b> shared outside our
                  ecosystem, minimizing the chance it&apos;s leaked, stolen, or
                  misused.
                </>
              }
            />
            <FloatIconFeature
              title='Eliminate Human Error'
              description='AI-powered screening ensures that every query is carried out
            with the appropriate rigor, protecting your company from
            needless human error.'
            />
          </div>
          <StaticImage
            className={classes.ecosystemImage}
            src={DashboardSignUpImg}
            alt='CycurID Dashboard Sign-Up'
            objectFit='contain'
            quality={100}
          />
        </div>
      </section>

      <CTABox
        title='CycurID prevents your business from getting bogged down in compliance.'
        description='To equip your company with a complete ID Protection and Management solution and capitalize on the opportunities of the next-gen marketplace, get in contact with our team today.'
        className={classes.ctaBox}
        bar='true'
      />

      <ContactUs />
    </main>
  )
}
