import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import routes from '../routes'
import CTALink from './CTALink'

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 10px 50px rgba(18, 17, 39, 0.08)',
    borderRadius: 10,
    padding: '30px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    margin: '0px 16px',
    [theme.breakpoints.up('tablet')]: {
      maxWidth: '80%',
      padding: '50px 20px 30px 110px',
      margin: '0px auto 0px auto',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 1128,
      width: '86%',
      padding: '80px 65px 60px 170px',
      margin: '0px auto 0px auto',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },

  head: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20.4px',
    letterSpacing: '0.8px',
    color: '#2FA43B',
    marginLeft: -65,
    [theme.breakpoints.up('tablet')]: {
      fontSize: 'calc(7px + 0.5vw)',
    },
    [theme.breakpoints.up('desktop')]: {
      marginLeft: -60,
    },
  },
  title: {
    textAlign: 'start',
    maxWidth: '90vw',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '33.6px',
    marginLeft: -67,
    [theme.breakpoints.up('tablet')]: {
      maxWidth: 314,
      fontSize: 'calc(12px + 1vw)',
      lineHeight: '140%',
      color: '#19313E',
    },
    [theme.breakpoints.up('desktop')]: {
      maxWidth: 441,
      fontSize: 'calc(23px + 0.9vw)',
      lineHeight: '56px',
      color: '#121127',
    },
    [theme.breakpoints.up('desktopXL')]: {
      maxWidth: 441,
      fontSize: '40px',
      lineHeight: '56px',
      color: '#121127',
    },
  },
  description: {
    textAlign: 'center',
    color: '#737373',
    marginTop: 10,
    marginBottom: 20,
    maxWidth: 500,
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('desktopXL')]: {
      marginBottom: 30,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 'calc(290px + 1vw)',
    maxWidth: '295px',
    // background: 'blue',
    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      width: '67%',
      maxWidth: '350px',
    },
    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      width: '100%',
      maxWidth: '400px',
    },
  },
  leftButton: {
    width: '100%',
    margin: '8px 0px',
    fontSize: '16px',
    fontWeight: 500,
    height: '52px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // background: 'orange',
    [theme.breakpoints.up('tablet')]: {
      width: 'unset',
      margin: '8px 6px',
      fontSize: 'calc(5px + 0.9vw)',
    },
    [theme.breakpoints.up('desktop')]: {
      fontSize: '16px',
    },
  },
  rightButton: {
    width: 130,
    [theme.breakpoints.up('tablet')]: {
      width: 'unset',
    },
  },
  background: {
    paddingTop: 64,
    height: 218,
    marginBottom: 165,

    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',

    [theme.breakpoints.up('tablet')]: {
      maxWidth: theme.custom.maxWidth,
      margin: 'auto',
      height: 172,
      paddingTop: 59,
      marginBottom: -150,
    },

    [theme.breakpoints.up('desktop')]: {
      marginBottom: 165,
    },
  },
}))
export default function ContactUs({ style, className, buttons }) {
  const classes = useStyles()

  const getLinkProps = (buttonText) => {
    switch (buttonText) {
      case 'Get Started': {
        return {
          route: process.env.GATSBY_DASHBOARD_SIGNUP_LINK,
          target: '_blank',
          rel: 'noopener',
        }
      }
      case 'Email Us Now':
      case 'Talk To Sales': {
        return { route: routes.CONTACT, target: '_self', rel: '' }
      }

      default: {
        return { route: routes.HOME, target: '_self', rel: '' }
      }
    }
  }
  return (
    <div className={classes.background}>
      <div style={style} className={`${classes.container} ${className}`}>
        <div className={classes.headContiner}>
          <Typography variant='h4' className={classes.head}>
            CONTACT US
          </Typography>
          <Typography variant='h2' className={classes.title}>
            <p style={{ margin: '10px 0px 24px 0px' }}>
              Get Digital Identity
              <br />
              Working For You{' '}
            </p>
          </Typography>
        </div>
        {/* <Typography variant='body2' className={classes.description}>
        {description}
      </Typography> */}
        <div className={classes.buttonContainer}>
          {buttons.map((buttonText, index) => {
            const linkProps = getLinkProps(buttonText)
            return (
              <CTALink
                key={buttonText}
                className={classes.leftButton}
                variant={index % 2 === 0 ? 'fill' : 'outline'}
                href={linkProps.route}
                target={linkProps.target}
                rel={linkProps.rel}
              >
                {buttonText}
              </CTALink>
            )
          })}
        </div>
      </div>
    </div>
  )
}
ContactUs.propTypes = {
  /**
   * Additional styles to apply to the container
   */
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  /**
   * Additional classnames to apply to container
   */
  className: PropTypes.string,
  /**
   * For each string in the array, a button will be created with the given label. Alternates fill and outline style
   */
  buttons: PropTypes.arrayOf(PropTypes.string),
}
ContactUs.defaultProps = {
  style: {},
  className: '',
  buttons: ['Get Started', 'Talk To Sales'],
}
